import React, { useEffect } from "react";
import "./PageNotFound.css";

function PageNotFound() {
  useEffect(() => {
    document.body.classList.remove("body-hover");
  }, []);
  return (
    <div className="pagenotFound-container">
      <div className="logo-text">
        <div className="text-404">
          <h1>404</h1>
          <p>
            The page you are trying to navigate to has been removed or not found
          </p>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
