import React, { useRef, useEffect, useState, useMemo } from "react";
import "./ImagesSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import Loading from "../Loading/Loading";

function ImagesSlider({ images }) {
  const isMobile = window.innerWidth <= 768;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);
  const [slideKey, setSlideKey] = useState(0);
  const [areImagesLoaded, setImagesLoaded] = useState(false);

  // Preload and sort images
  const sortedImages = useMemo(() => {
    return images
      .slice()
      .sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder));
  }, [images]);

  useEffect(() => {
    // Preload all images and update the loaded state
    let loadedImagesCount = 0;
    sortedImages.forEach((image) => {
      const img = new Image();
      img.src = `${process.env.REACT_APP_BACKEND_URL_IMAGES}${image.imageUrl}`;
      img.onload = () => {
        loadedImagesCount += 1;
        if (loadedImagesCount === sortedImages.length) {
          setImagesLoaded(true); // All images have loaded
        }
      };
    });
  }, [sortedImages]);

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";

    sortedImages.forEach((image) => {
      const className = image.name.replace(/\s+/g, "-");
      const imageUrl = image.imageUrl
        ? `${process.env.REACT_APP_BACKEND_URL_IMAGES}${image.imageUrl}`
        : null;

      if (imageUrl) {
        styleSheet.innerHTML += `
          [class*="${className}"] {
            background-image: url('${imageUrl}');
            background-size: cover;
            background-position: center;
          }
        `;
      }
    });

    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [sortedImages]);

  useEffect(() => {
    setSlideKey((prevKey) => prevKey + 1);
  }, [isMobile, areImagesLoaded]);

  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.update();
    }
  }, [swiperRef, prevRef, nextRef]);

  return (
    <div className="slider-container">
      <div ref={prevRef} className="custom-prev-arrow">
        <img src="/images/leftarrow.svg" alt="left arrow" />
      </div>
      <div ref={nextRef} className="custom-next-arrow">
        <img src="/images/rightarrow.svg" alt="right arrow" />
      </div>

      {areImagesLoaded ? (
        <Swiper
          key={slideKey}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          slidesPerView="auto"
          loop={true}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          style={{ height: "100vh" }}
          className="swiper-container"
        >
          {sortedImages.length > 0 ? (
            sortedImages.map((image, index) => {
              const className = image.name.replace(/\s+/g, "-");
              const uniqueKey = `${className}-${index}`;

              return (
                <SwiperSlide key={uniqueKey} className={className}>
                  {!isMobile && (
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL_IMAGES}${image.imageUrl}`}
                      alt={image.alt || `Slide ${index + 1}`}
                    />
                  )}
                </SwiperSlide>
              );
            })
          ) : (
            <SwiperSlide>
              <p>No images available</p>
            </SwiperSlide>
          )}
        </Swiper>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default ImagesSlider;
