import React, { useState, useRef, useEffect } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "./SocialMedia.css";
import DashSideBar from "../../shared/DashSideBar";
import axios from "axios";

function SocialMedia() {
  let emptySocialMedia = {
    id: null,
    name: "",
    url: "",
  };

  const [socialMedias, setSocialMedias] = useState(null);
  const [socialMediaDialog, setSocialMediaDialog] = useState(false);
  const [deleteSocialMediaDialog, setDeleteSocialMediaDialog] = useState(false);
  const [deleteSocialMediasDialog, setdeleteSocialMediasDialog] =
    useState(false);
  const [SocialMedia, setSocialMedia] = useState(emptySocialMedia);
  const [selectedSocialMedias, setselectedSocialMedias] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getSocialMedia();
  }, []);

  const getSocialMedia = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "api/SocialMedias"
      );

      if (response.data) {
        setSocialMedias(response.data);
      }
    } catch (error) {
      console.error("Error fetching socialMedia:", error);
    }
  };

  const openNew = () => {
    setSocialMedia(emptySocialMedia);
    setSubmitted(false);
    setSocialMediaDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setSocialMediaDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteSocialMediaDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setdeleteSocialMediasDialog(false);
  };

  const saveProduct = async () => {
    setLoading(true);
    setSubmitted(true);

    if (SocialMedia.name.trim()) {
      let _products = [...socialMedias];
      let _product = { ...SocialMedia };

      if (SocialMedia.id) {
        const index = findIndexById(SocialMedia.id);

        let data = {
          Id: SocialMedia.id,
          Name: SocialMedia.name,
          Url: SocialMedia.url,
        };

        const response = await axios.put(
          process.env.REACT_APP_BACKEND_URL +
            "api/SocialMedias/" +
            SocialMedia.id,
          data
        );
        setLoading(false);
        if (response.data.success) {
          _products[index] = _product;
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Social Media Updated",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Social Media Not Updated",
            life: 3000,
          });
        }
      } else {
        _product.id = createId();
        let data = { Name: _product.name, Url: SocialMedia.url };
        const response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "api/SocialMedias",
          data
        );

        setLoading(false);
        if (response.data.success) {
          _products.push(response.data.socialMedia);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Social Media Created",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Social Media Not Created",
            life: 3000,
          });
        }
      }

      setSocialMedias(_products);
      setSocialMediaDialog(false);
      setSocialMedia(emptySocialMedia);
    }
  };

  const editProduct = (SocialMedia) => {
    setSocialMedia({ ...SocialMedia });
    setSocialMediaDialog(true);
  };

  const confirmDeleteProduct = (SocialMedia) => {
    setSocialMedia(SocialMedia);
    setDeleteSocialMediaDialog(true);
  };

  const deleteProduct = async () => {
    setLoading(true);
    const response = await axios.delete(
      process.env.REACT_APP_BACKEND_URL + "api/SocialMedias/" + SocialMedia.id
    );
    setLoading(false);
    if (response.data.success) {
      let _products = socialMedias.filter((val) => val.id !== SocialMedia.id);
      setSocialMedias(_products);
      setDeleteSocialMediaDialog(false);
      setSocialMedia(emptySocialMedia);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Social Media Deleted",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Social Media Not Deleted",
        life: 3000,
      });
    }
  };

  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < socialMedias.length; i++) {
      if (socialMedias[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setdeleteSocialMediasDialog(true);
  };

  const deleteSelectedProducts = async () => {
    setLoading(true);
    const ids = selectedSocialMedias.map((SocialMedia) => SocialMedia.id);
    const idsString = ids.join(",");
    let data = { Ids: idsString };
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        "api/SocialMedias/DeleteSocialMediaByIds",
      data
    );
    setLoading(false);
    if (response.data.success) {
      let _products = socialMedias.filter(
        (val) => !selectedSocialMedias.includes(val)
      );
      setSocialMedias(_products);
      setdeleteSocialMediasDialog(false);
      setselectedSocialMedias(null);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "social Media Deleted",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "social Media Not Deleted",
        life: 3000,
      });
    }
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...SocialMedia };

    _product[`${name}`] = val;

    setSocialMedia(_product);
  };
  const leftToolbarTemplate = () => {
    return (
      <div className="d-flex align-items-center justify-content-center gap-5">
        <h4 className="m-0 title-of-table">Manage SocialMedias</h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            style={{ border: "1px solid black" }}
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
          />
        </span>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="d-flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedSocialMedias || !selectedSocialMedias.length}
        />
      </div>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          style={{ marginRight: "10px" }}
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };
  const header = (
    <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between">
      {/* <h4 className="m-0">Manage SocialMedias</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span> */}
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label="Save"
        loading={loading}
        icon="pi pi-check"
        onClick={saveProduct}
      />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        loading={loading}
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        loading={loading}
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  return (
    <div className="col-12 d-flex">
      <div className="col-3">
        <DashSideBar />
      </div>
      <div className="col-9">
        <div className="SocialMedia-container">
          <Toast ref={toast} />
          <div className="card">
            <Toolbar
              className="mb-3"
              start={leftToolbarTemplate}
              end={rightToolbarTemplate}
            ></Toolbar>

            <DataTable
              ref={dt}
              value={socialMedias}
              selection={selectedSocialMedias}
              onSelectionChange={(e) => setselectedSocialMedias(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} SocialMedias"
              globalFilter={globalFilter}
              header={header}
            >
              <Column selectionMode="multiple" exportable={false}></Column>
              <Column
                field="id"
                header="Code"
                sortable
                width="150px"
                style={{ textAlign: "center" }}
              ></Column>
              <Column
                field="name"
                header="Name"
                sortable
                style={{ minWidth: "16rem" }}
              ></Column>
              <Column
                field="url"
                header="Value"
                sortable
                style={{ minWidth: "16rem" }}
              ></Column>
              <Column
                body={actionBodyTemplate}
                exportable={false}
                style={{ minWidth: "12rem" }}
              ></Column>
            </DataTable>
          </div>

          <Dialog
            visible={socialMediaDialog}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header="SocialMedia Details"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="name" className="font-bold mb-1">
                Name :
              </label>
              <InputText
                id="name"
                value={SocialMedia.name}
                onChange={(e) => onInputChange(e, "name")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !SocialMedia.name,
                })}
              />
              {submitted && !SocialMedia.name && (
                <small className="p-error">Name is required.</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="url" className="font-bold mb-1">
                Value :
              </label>
              <InputText
                id="url"
                value={SocialMedia.url}
                onChange={(e) => onInputChange(e, "url")}
                required
                className={classNames({
                  "p-invalid": submitted && !SocialMedia.url,
                })}
              />
              {submitted && !SocialMedia.url && (
                <small className="p-error">Value is required.</small>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteSocialMediaDialog}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header="Confirm"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteProductDialog}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{
                  textAlign: "center",
                  fontSize: "2rem",
                  width: "100%",
                  color: "#6659ff",
                }}
              />
              <div>
                <p style={{ textAlign: "center", marginBottom: "5px" }}>
                  Are you sure you want to delete:
                </p>
                {SocialMedia && (
                  <p style={{ textAlign: "center", marginBottom: "0" }}>
                    <b>{SocialMedia.name}</b>
                  </p>
                )}
              </div>
            </div>
          </Dialog>

          <Dialog
            visible={deleteSocialMediasDialog}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header="Confirm"
            modal
            footer={deleteProductsDialogFooter}
            onHide={hideDeleteProductsDialog}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{
                  textAlign: "center",
                  fontSize: "2rem",
                  width: "100%",
                  color: "#6659ff",
                }}
              />
              {SocialMedia && (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "25px",
                  }}
                >
                  Are you sure you want to delete the selected Social Media?
                </p>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default SocialMedia;
