import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="mobile-version">
        <Link to="/about">ABOUT</Link>
        {/* <Link to="/">PROJECTS</Link> */}
        <Link to="/contact">LET’S CONNECT</Link>
      </div>
    </div>
  );
}

export default Footer;
