import React, { useEffect, useState } from "react";
import "./Contact.css";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import { useLocation } from "react-router-dom";

function Contact() {
  const [socialMedia, setSocialMedia] = useState([]);
  const [containerHeight, setContainerHeight] = useState("100vh");
  const location = useLocation(); // Get the current pathname

  useEffect(() => {
    getSocials();
    calculateHeight();

    if (location.pathname.includes("contact")) {
      document.body.style.backgroundColor = "unset"; // Remove background color
    }
    window.addEventListener("resize", calculateHeight);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", calculateHeight);
      document.body.style.backgroundColor = ""; // Restore background color
    };
  }, []);
  const calculateHeight = () => {
    const navbarHeight =
      document.querySelector(".navbar-container")?.offsetHeight || 0;
    const footerHeight = document.querySelector(".footer")?.offsetHeight || 0;
    const availableHeight = `calc(100vh - ${navbarHeight}px - ${footerHeight}px)`;
    setContainerHeight(availableHeight);
  };
  const getSocials = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "api/SocialMedias"
      );
      if (response.data) setSocialMedia(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const getSocialMediaLink = (socialMedia) => {
    switch (socialMedia.name.toLowerCase()) {
      case "gmail":
        return "mailto:" + socialMedia.url; // Replace with actual Gmail email
      case "linkedin":
        return socialMedia.url; // Replace with your LinkedIn profile URL
      case "instagram":
        return socialMedia.url; // Replace with your Instagram profile URL
      default:
        return "#"; // Default link if no match found
    }
  };
  const getHoverText = (socialMediaName) => {
    switch (socialMediaName.toLowerCase()) {
      case "gmail":
        return "PROFESSIONAL";
      case "linkedin":
        return "VERY PROFESSIONAL";
      case "instagram":
        return "TOTALLY UNPROFESSIONAL";
      default:
        return socialMediaName;
    }
  };
  return (
    <div className="contact-container" style={{ height: containerHeight }}>
      {socialMedia.length > 0 ? (
        <div className="contactinfo">
          <div className="socialmedia-container">
            {socialMedia.map((element, index) => {
              // Calculate left position based on index
              let leftPosition;

              if (index === socialMedia.length - 1) {
                // Less negative for the last item
                leftPosition = -500; // You can adjust this value as needed
              } else if (index === socialMedia.length - 2) {
                // More negative for all other items
                leftPosition = -392;
              } else {
                leftPosition = -220; // First item -200, second -300, third -400, etc.
              }

              return (
                <div className="socialMedia-item" key={index}>
                  <a
                    href={getSocialMediaLink(element)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="social-media-text">{element.name}</span>
                    <span
                      className="hover-text"
                      style={{ left: `${leftPosition}px` }}
                    >
                      {getHoverText(element.name)}
                    </span>
                  </a>
                </div>
              );
            })}
            <div className="smileyface">
              <img
                src="images/file.png"
                // width={500}
                // height={630}
                alt="Smiley Face"
              />
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Contact;
