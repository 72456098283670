import React, { useContext, useEffect, memo } from "react";
import { ProjectsContext } from "../../ProjectContext/ProjectsProvider";
import "./ProjectDetails.css";
import VideosSlider from "../../components/VideosSlider/VideosSlider";
import ImagesSlider from "../../components/ImagesSlider/ImagesSlider";
import { Link } from "react-router-dom";

function ProjectDetails({ projectName }) {
  const { projects } = useContext(ProjectsContext);
  const selectedProject = projects.find(
    (project) => project.name.toLowerCase() === projectName.toLowerCase()
  );

  const mainSliderVideos = selectedProject.videos.filter(
    (video) => video.sliderPosition === 0 // Main Slider
  );
  const secondSliderVideos = selectedProject.videos.filter(
    (video) => video.sliderPosition === 1 // Second Slider
  );

  const mainSliderImages = selectedProject.images.filter(
    (image) => image.sliderPosition === 0 // Main Slider
  );
  const secondSliderImages = selectedProject.images.filter(
    (image) => image.sliderPosition === 1 // Second Slider
  );
  useEffect(() => {
    // Select the navbar-container and set its margin-top to 0
    const navbar = document.querySelector(".navbar-container");
    if (navbar) {
      navbar.style.marginTop = "0";
      navbar.style.margin = "25px auto"; // Override to 0 for this component
    }

    // Optional: Clean up and reset the margin-top when component unmounts
    return () => {
      if (navbar) {
        navbar.style.marginTop = "40px"; // Reset to original if needed
      }
    };
  }, []);
  useEffect(() => {
    document.body.classList.remove("body-hover");
  }, []);
  return (
    <div className="projectDetails-container">
      <div className="projectnavbar">
        <Link to="/" className="smileyfaceproject">
          <img src="/images/Smiley.webp" alt="smiley face icon" />
        </Link>
      </div>
      <div className="videoSlider-container">
        <VideosSlider videos={mainSliderVideos} />
        {/* {selectedProject.videos.length > 1 ? (
          <div className="swipe-text">
            <p>SWIPE TO CHECK THE REST</p>
            <img src="/Icons/arrow-02.svg" alt="right arrow" />
          </div>
        ) : (
          ""
        )} */}
      </div>
      <div className="infos-container" id="projetInfo">
        <p>{selectedProject.description}</p>
      </div>
      <div className="imagesSlider-container">
        {mainSliderImages.length > 0 && (
          <>
            <ImagesSlider images={mainSliderImages} />
            {/* {mainSliderImages.length > 1 && (
              <div className="swipe-text">
                <p>SWIPE TO CHECK THE REST</p>
                <img src="/Icons/arrow-02.svg" alt="right arrow" />
              </div>
            )} */}
          </>
        )}
      </div>
      <div className="second-imageSlider-container">
        {secondSliderImages.length > 0 && (
          <>
            <ImagesSlider images={secondSliderImages} />
            {/* {secondSliderImages.length > 1 && (
              <div className="swipe-text-second-image">
                <p>SWIPE TO CHECK THE REST</p>
                <img src="/Icons/arrow-02.svg" alt="right arrow" />
              </div>
            )} */}
          </>
        )}
      </div>
      <div className="second-videoSlider-container">
        {secondSliderVideos.length > 0 && (
          <>
            <VideosSlider videos={secondSliderVideos} />
            {/* {secondSliderVideos.length > 1 && (
              <div className="swipe-text-second-video">
                <p>SWIPE TO CHECK THE REST</p>
                <img src="/Icons/arrow-02.svg" alt="right arrow" />
              </div>
            )} */}
          </>
        )}
      </div>
    </div>
  );
}

export default memo(ProjectDetails);
