import React, { useState, useRef, useEffect } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import "./ProjectImages.css";
import DashSideBar from "../../shared/DashSideBar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import Resizer from "react-image-file-resizer";

function ProjectImages() {
  let emptyProjectImage = {
    id: null,
    name: "",
    alt: "",
    sliderPosition: null,
    sortOrder: null,
    imageUrl: null,
    project: { id: null, name: "" },
  };

  const [projectImages, setProjectImages] = useState(null);
  const [projects, setProjects] = useState(null);
  const [projectImageDialog, setProjectImageDialog] = useState(false);
  const [deleteProjectImageDialog, setDeleteProjectImageDialog] =
    useState(false);
  const [deletProjectImagesDialog, setDeletProjectImagesDialog] =
    useState(false);
  const [projectImage, setProjectImage] = useState(emptyProjectImage);
  const [selectedProjectImages, setSelectedProjectImages] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    getProjectImages();
    getProjects();
  }, []);

  const getProjectImages = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "api/projectImages"
      );

      if (response.data) {
        setProjectImages(response.data);
      }
    } catch (error) {
      console.error("Error fetching projectImages:", error);
    }
  };
  const getProjects = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "api/Projects/GetProjectsForProjectMaterials"
      );

      if (response.data) {
        setProjects(response.data);
      }
    } catch (error) {
      console.error("Error fetching projectImages:", error);
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        2000, // width
        2000, // height
        "WEBP", // format (can be "JPEG", "PNG", "WEBP")
        100, // quality (0-100)
        0, // rotation (optional)
        (uri) => {
          resolve(uri);
        },
        "blob" // output type (blob or base64)
      );
    });

  const openNew = () => {
    setProjectImage(emptyProjectImage);
    setSubmitted(false);
    setProjectImageDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProjectImageDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProjectImageDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeletProjectImagesDialog(false);
  };

  const saveProjectImage = async () => {
    setLoading(true);
    setSubmitted(true);

    if (projectImage.name.trim()) {
      let _projectImages = [...projectImages];
      let _projectImage = { ...projectImage };

      if (projectImage.id) {
        const index = findIndexById(projectImage.id);

        let formData = new FormData();
        formData.append("name", _projectImage.name);
        formData.append("alt", _projectImage.alt);
        formData.append("projectId", _projectImage.project.id);
        formData.append("sliderPosition", _projectImage.sliderPosition);
        formData.append("sortOrder", parseInt(_projectImage.sortOrder));

        if (file != null) {
          formData.append("imageUrl", file);
        }
        const response = await axios.put(
          process.env.REACT_APP_BACKEND_URL +
            "api/ProjectImages/" +
            projectImage.id,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        setLoading(false);
        setFile(null);

        if (response.data.success) {
          _projectImages[index] = _projectImage;
          if (response.data.fileName !== "")
            _projectImages[index].imageUrl = response.data.fileName;
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Image Updated",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Image Not Updated",
            life: 3000,
          });
        }
      } else {
        _projectImage.id = createId();
        let formData = new FormData();
        formData.append("name", _projectImage.name);
        formData.append("alt", _projectImage.alt);
        formData.append("projectId", _projectImage.project.id);
        formData.append("sliderPosition", _projectImage.sliderPosition);
        formData.append("sortOrder", parseInt(_projectImage.sortOrder));
        formData.append("imageUrl", file);

        const response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "api/ProjectImages",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        setLoading(false);
        setFile(null);
        if (response.data.success) {
          console.log(response.data);
          _projectImages.push(response.data.projectImage);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Image Added",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Image Not Added",
            life: 3000,
          });
        }
      }

      setProjectImages(_projectImages);

      setProjectImageDialog(false);
      setProjectImage(emptyProjectImage);
    }
  };

  const editProduct = (projectImage) => {
    console.log(projectImage);
    setProjectImage({ ...projectImage });
    setProjectImageDialog(true);
  };

  const confirmDeleteProduct = (projectImage) => {
    setProjectImage(projectImage);
    setDeleteProjectImageDialog(true);
  };

  const deleteProduct = async () => {
    setLoading(true);
    const response = await axios.delete(
      process.env.REACT_APP_BACKEND_URL + "api/ProjectImages/" + projectImage.id
    );
    setLoading(false);
    if (response.data.success) {
      let _projectImages = projectImages.filter(
        (val) => val.id !== projectImage.id
      );
      setProjectImages(_projectImages);
      setDeleteProjectImageDialog(false);
      setProjectImage(emptyProjectImage);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Project Image Deleted",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Project Image Not Deleted",
        life: 3000,
      });
    }
  };

  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < projectImages.length; i++) {
      if (projectImages[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        loading="lazy"
        src={process.env.REACT_APP_BACKEND_URL_IMAGES + rowData.imageUrl}
        alt={rowData.imageUrl}
        className="shadow-2 border-round"
        style={{ width: "64px" }}
      />
    );
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeletProjectImagesDialog(true);
  };

  const deleteSelectedProducts = async () => {
    setLoading(true);
    const ids = selectedProjectImages.map((projectImage) => projectImage.id);
    const idsString = ids.join(",");
    let data = { Ids: idsString };
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        "api/ProjectImages/DeleteProjectImagesByIds",
      data
    );
    setLoading(false);
    if (response.data.success) {
      let _projectImages = projectImages.filter(
        (val) => !selectedProjectImages.includes(val)
      );
      setProjectImages(_projectImages);
      setDeletProjectImagesDialog(false);
      setSelectedProjectImages(null);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "projectImages Deleted",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "projectImages Not Deleted",
        life: 3000,
      });
    }
  };
  const onSliderPositionChange = (e) => {
    setProjectImage({
      ...projectImage,
      sliderPosition: e.value, // e.value will now be 0 or 1
    });
  };

  const onProjectChange = (selectedOption) => {
    const selectedProjectId = selectedOption.value; // Assuming 'value' contains the selected category ID

    const selectedProject = projects.find(
      (project) => project.id === selectedProjectId
    );
    console.log(selectedProject);

    setProjectImage({
      ...projectImage,
      project: { id: selectedOption.value, name: selectedProject.name },
    });
  };
  const onFileUpload = async (event) => {
    setLoading(true);
    const file = event.files[0];

    const resizedImage = await resizeFile(file); // Resize the image

    // Create a new File from the blob with the correct name and MIME type
    const newFile = new File([resizedImage], file.name, {
      type: resizedImage.type || "image/jpeg", // Set MIME type
    });
    setFile(newFile);

    setProjectImage((prevProjectImage) => ({
      ...prevProjectImage,
      imageUrl: process.env.REACT_APP_BACKEND_URL_IMAGES + newFile.name, // Save the file name as the image path for simplicity
    }));
    setLoading(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _projectImage = { ...projectImage };

    _projectImage[`${name}`] = val;

    setProjectImage(_projectImage);
  };
  const leftToolbarTemplate = () => {
    return (
      <div className="d-flex align-items-center justify-content-center gap-5">
        <h4 className="m-0 title-of-table">Manage Project Images</h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            style={{ border: "1px solid black" }}
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
          />
        </span>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="d-flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedProjectImages || !selectedProjectImages.length}
        />
      </div>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          style={{ marginRight: "10px" }}
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };
  const header = (
    <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between">
      {/* <h4 className="m-0">Manage Project Images</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span> */}
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label="Save"
        loading={loading}
        icon="pi pi-check"
        onClick={saveProjectImage}
      />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        loading={loading}
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        loading={loading}
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  return (
    <div className="col-12 d-flex">
      <div className="col-3">
        <DashSideBar />
      </div>
      <div className="col-9">
        <div className="projectImage-container">
          <Toast ref={toast} />
          <div className="card">
            <Toolbar
              className="mb-3"
              start={leftToolbarTemplate}
              end={rightToolbarTemplate}
            ></Toolbar>

            <DataTable
              ref={dt}
              value={projectImages}
              selection={selectedProjectImages}
              onSelectionChange={(e) => setSelectedProjectImages(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Project Images"
              globalFilter={globalFilter}
              header={header}
            >
              <Column selectionMode="multiple" exportable={false}></Column>
              <Column
                field="id"
                header="Code"
                sortable
                width="150px"
                style={{ textAlign: "center" }}
              ></Column>
              <Column
                field="imageUrl"
                header="Image"
                body={imageBodyTemplate}
              ></Column>
              <Column
                field="name"
                header="Name"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>

              <Column
                field="alt"
                header="Description"
                style={{ minWidth: "12rem" }}
              ></Column>
              <Column
                field="sliderPosition"
                sortable
                header="Slider Video Position"
                style={{ textAlign: "center" }}
                body={(rowData) => {
                  // Check the value of sliderPosition and return the appropriate label
                  return rowData.sliderPosition === 0
                    ? "Main Slider"
                    : "Second Slider";
                }}
              ></Column>
              <Column
                field="sortOrder"
                sortable
                header="Order"
                style={{ textAlign: "center" }}
              ></Column>
              <Column
                field="project.name"
                header="Project"
                sortable
                style={{ minWidth: "12rem" }}
              ></Column>

              <Column
                body={actionBodyTemplate}
                exportable={false}
                style={{ minWidth: "12rem" }}
              ></Column>
            </DataTable>
          </div>

          <Dialog
            visible={projectImageDialog}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header="Project Image Details"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            {projectImage.imageUrl && (
              <div className="image-container">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    projectImage.imageUrl
                  }
                  alt={projectImage.imageUrl}
                  className="product-image block m-auto pb-3"
                />
              </div>
            )}
            <div className="field">
              <label htmlFor="name" className="font-bold mb-1">
                Name :
              </label>
              <InputText
                id="name"
                value={projectImage.name}
                onChange={(e) => onInputChange(e, "name")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !projectImage.name,
                })}
              />
              {submitted && !projectImage.name && (
                <small className="p-error">Name is required.</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="alt" className="font-bold mt-3 mb-1">
                Description :
              </label>
              <InputTextarea
                id="alt"
                value={projectImage.alt}
                onChange={(e) => onInputChange(e, "alt")}
                required
                className={classNames({
                  "p-invalid": submitted && !projectImage.alt,
                })}
              />
              {submitted && !projectImage.alt && (
                <small className="p-error">Description is required.</small>
              )}
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Slider Position</label>
              <Dropdown
                value={projectImage.sliderPosition}
                options={[
                  { value: 0, label: "Main Slider" }, // MainSlider as 0
                  { value: 1, label: "Second Slider" }, // SecondSlider as 1
                ]}
                onChange={onSliderPositionChange}
                placeholder="Select a Slider Position"
              />
            </div>
            <div className="field">
              <label htmlFor="sortOrder" className="font-bold mt-3 mb-1">
                Order :
              </label>
              <InputText
                id="sortOrder"
                type="number"
                value={projectImage.sortOrder}
                onChange={(e) => onInputChange(e, "sortOrder")}
                required
                className={classNames({
                  "p-invalid": submitted && !projectImage.sortOrder,
                })}
              />
              {submitted && !projectImage.sortOrder && (
                <small className="p-error">sortOrder is required.</small>
              )}
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Porject</label>
              {projects != null ? (
                <Dropdown
                  value={projectImage.project.id}
                  options={projects.map((project) => ({
                    value: project.id,
                    label: project.name,
                  }))}
                  onChange={onProjectChange}
                  placeholder="Select a Project"
                />
              ) : (
                <p>Loading projects...</p>
              )}
            </div>
            <div className="field">
              <label htmlFor="image" className="mt-3 mb-1 font-bold">
                Image :
              </label>
              <FileUpload
                name="image"
                mode="basic"
                accept="image/*"
                onSelect={onFileUpload}
              />
            </div>
          </Dialog>

          <Dialog
            visible={deleteProjectImageDialog}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header="Confirm"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteProductDialog}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{
                  textAlign: "center",
                  fontSize: "2rem",
                  width: "100%",
                  color: "#6659ff",
                }}
              />
              <div>
                <p style={{ textAlign: "center", marginBottom: "5px" }}>
                  Are you sure you want to delete:
                </p>
                {projectImage && (
                  <p style={{ textAlign: "center", marginBottom: "0" }}>
                    <b>{projectImage.name}</b>
                  </p>
                )}
              </div>
            </div>
          </Dialog>

          <Dialog
            visible={deletProjectImagesDialog}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header="Confirm"
            modal
            footer={deleteProductsDialogFooter}
            onHide={hideDeleteProductsDialog}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{
                  textAlign: "center",
                  fontSize: "2rem",
                  width: "100%",
                  color: "#6659ff",
                }}
              />
              {projectImage && (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "25px",
                  }}
                >
                  Are you sure you want to delete the selected projects?
                </p>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default ProjectImages;
