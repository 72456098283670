import React, { useState, useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NavBar from "../MainWebsite/shared/NavBar/NavBar";
import Footer from "../MainWebsite/shared/footer/Footer";
import Contact from "../MainWebsite/pages/contact/Contact";
import About from "../MainWebsite/pages/about/About";
import Home from "../MainWebsite/pages/home/Home";
import PageNotFound from "../PageNotFound/PageNotFound";
import { ProjectsContext } from "../MainWebsite/ProjectContext/ProjectsProvider";
import ProjectDetails from "../MainWebsite/pages/ProjectDetails/ProjectDetails";
import Loading from "../MainWebsite/components/Loading/Loading";

function MainRoutes() {
  const { projects, loading } = useContext(ProjectsContext);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const isProjectPage = location.pathname.startsWith("/project/");
  const getFirstProjectName = () => {
    if (projects && projects.length > 0) {
      return projects[0].name;
    }
    return "";
  };
  useEffect(() => {
    if (isProjectPage) {
      document.body.style.backgroundColor = "unset"; // This resets the background color
    }
  }, [isProjectPage, isMobile]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {(!isProjectPage || isMobile) && (
        <NavBar firstProjectName={getFirstProjectName()} />
      )}
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {projects != null &&
          projects.map((project) => {
            return (
              <Route
                key={project.id}
                path={`/project/${project.name
                  .replace(/\s+/g, "-")
                  .toLowerCase()}`}
                element={<ProjectDetails projectName={project.name} />}
              />
            );
          })}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {!isProjectPage && <Footer />}
    </>
  );
}

export default MainRoutes;
