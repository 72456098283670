import React, { useState } from "react";
import "./DashSideBar.css";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";
import { Button } from "primereact/button";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function DashSideBar() {
  const { user, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const navigationLinks = [
    {
      id: 1,
      path: "/dashboard/aboutPage",
      label: "About Page",
      icon: "bi bi-info-circle",
    },
    {
      id: 2,
      path: "/dashboard/projects",
      label: "Projects",
      icon: "bi bi-server",
    },
    {
      id: 3,
      path: "/dashboard/projectImages",
      label: "Project Images",
      icon: "bi bi-file-image-fill",
    },
    {
      id: 4,
      path: "/dashboard/projectVideos",
      label: "Project Videos",
      icon: "bi bi-file-earmark-play-fill",
    },
    {
      id: 5,
      path: "/dashboard/SocialMedia",
      label: "Social Media",
      icon: "bi bi-alexa",
    },

    // Add more objects as needed
  ];

  const handleLogout = async () => {
    setLoading(true);
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "api/User/SignOut"
    );
    logout();
    setLoading(false);

    if (response.data.success) {
      navigate("/dashboard/signin");
    }
  };
  return (
    <div
      className="d-flex flex-column flex-shrink-0 p-3 text-white  col-3"
      style={{
        height: "100vh",
        position: "fixed",
        left: "0",
        backgroundColor: "#2a4c4e",
      }}
    >
      <Link
        to="/dashboard/projects"
        className="d-flex justify-content-center align-items-center mb-3 mb-md-0  text-white text-decoration-none"
        style={{ fontWeight: "bold", fontSize: "1.5rem" }}
      >
        TAREK SABBOUH
      </Link>

      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        {navigationLinks.map((link) => (
          <li key={link.id}>
            <NavLink
              to={link.path}
              className="nav-link text-white list-group-item-action"
              style={({ isActive }) =>
                isActive ? { backgroundColor: "#40908e" } : {}
              }
            >
              <i className={link.icon} style={{ paddingRight: "2rem" }} />
              {link.label}
            </NavLink>
          </li>
        ))}
      </ul>
      <hr />
      <div className="dropdown">
        <p
          style={{ cursor: "pointer" }}
          className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
          id="dropdownUser1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i
            className="bi bi-person-circle"
            style={{ paddingRight: "20px", fontSize: "2rem" }}
          />
          <strong>{user.userName}</strong>
        </p>
        <ul
          className="dropdown-menu dropdown-menu-dark text-small shadow"
          aria-labelledby="dropdownUser1"
        >
          <li>
            <Link className="dropdown-item" to="/dashboard/settings">
              Profile
            </Link>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <Button
              className="dropdown-item"
              onClick={handleLogout}
              loading={loading}
            >
              Sign out
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DashSideBar;
