import React, { useEffect, useState } from "react";
import "./About.css";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import { useLocation } from "react-router-dom";

function About() {
  const [content, setContent] = useState("");
  const [containerHeight, setContainerHeight] = useState("100vh");
  const [error, setError] = useState(null); // State for error handling
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial check for mobile

  const location = useLocation();
  const isAboutPage = location.pathname === "/about";

  // Function to check if the user is on mobile
  const checkIfMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust this width as needed
  };
  useEffect(() => {
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);
  const calculateHeight = () => {
    const navbarHeight =
      document.querySelector(".navbar-container")?.offsetHeight || 0;
    const footerHeight = document.querySelector(".footer")?.offsetHeight || 0;
    const availableHeight = `calc(100vh - ${navbarHeight}px - ${footerHeight}px)`;
    setContainerHeight(availableHeight);
  };

  useEffect(() => {
    // Fetch content on mount and when the window is resized
    getContentAbout();

    // Add resize event listener to update isMobile on window size change
    window.addEventListener("resize", checkIfMobile);

    // Cleanup function to remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []); // Empty dependency array to run only on mount

  useEffect(() => {
    if (isAboutPage) {
      // Change the body's background color to green when on the About page
      document.body.style.backgroundColor = "#00fc49";
    }

    // Cleanup function to reset background color when the component unmounts or route changes
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [isAboutPage]);

  const getContentAbout = async () => {
    try {
      const endpoint = isMobile
        ? `${process.env.REACT_APP_BACKEND_URL}api/AboutPages/getSecondOne` // Endpoint for mobile content
        : `${process.env.REACT_APP_BACKEND_URL}api/AboutPages/getFirstOne`; // Endpoint for desktop content

      const response = await axios.get(endpoint);
      if (response.data) {
        setContent(response.data.description);
      } else {
        setContent(""); // In case no content is returned
      }
    } catch (error) {
      console.error("Error fetching about page content:", error);
      setError("Failed to load content. Please try again later."); // Set an error message
    }
  };

  // Add useEffect to fetch content based on the current mobile state
  useEffect(() => {
    getContentAbout(); // Fetch content whenever isMobile changes
  }, [isMobile]);

  return (
    <div className="aboutus-container" style={{ height: containerHeight }}>
      {error ? (
        <div className="error-message">{error}</div> // Display error message
      ) : content ? (
        <div
          className="about-content"
          dangerouslySetInnerHTML={{ __html: content }} // Rendering content as HTML
        />
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default About;
