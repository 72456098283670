import React, { useContext, useState, useEffect } from "react";
import "./Home.css";
import { ProjectsContext } from "../../ProjectContext/ProjectsProvider";
import Loading from "../../components/Loading/Loading";
import { Link } from "react-router-dom";

function Home() {
  const { projects } = useContext(ProjectsContext);
  const [containerHeight, setContainerHeight] = useState("100vh");

  const [lastInRow, setLastInRow] = useState([]); // Track the last element in each row
  const [imageClasses, setImageClasses] = useState({});

  const handleImageLoad = (index, image) => {
    const width = image.naturalWidth;
    const height = image.naturalHeight;

    // Define a class based on dimensions
    let imageClass = "";
    if (width > height) {
      imageClass = "landscape"; // Wider than tall
    } else if (height > width) {
      imageClass = "portrait"; // Taller than wide
    } else {
      imageClass = "square"; // Equal dimensions
    }

    setImageClasses((prevClasses) => ({
      ...prevClasses,
      [index]: imageClass,
    }));
  };

  useEffect(() => {
    const calculateHeight = () => {
      const navbarHeight =
        document.querySelector(".navbar-container").offsetHeight;
      const footerHeight = document.querySelector(".footer").offsetHeight;
      const availableHeight = `calc(100vh - ${navbarHeight}px - ${footerHeight}px)`;
      setContainerHeight(availableHeight);
    };

    // Run the calculation when the component mounts
    calculateHeight();

    // Optionally, add a resize listener to adjust height if window is resized
    window.addEventListener("resize", calculateHeight);

    // Cleanup the resize event listener when component unmounts
    return () => {
      // document.body.style.backgroundColor = "#00fc49";
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);
  // Detect line breaks using element positioning
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const links = document.querySelectorAll(".project-link");
      const positions = [];
      const newLastInRow = [];

      links.forEach((link, index) => {
        const rect = link.getBoundingClientRect();
        const top = rect.top;

        if (positions.length === 0 || positions[positions.length - 1] !== top) {
          positions.push(top);
          if (positions.length > 1) {
            newLastInRow[positions.length - 2] = index - 1;
          }
        }

        if (index === links.length - 1) {
          newLastInRow[positions.length - 1] = index;
        }
      });

      setLastInRow(newLastInRow);
    });

    const links = document.querySelectorAll(".project-link");
    links.forEach((link) => observer.observe(link));

    return () => {
      observer.disconnect();
    };
  }, [projects]);

  const toSlug = (name) => {
    return name.toLowerCase().replace(/\s+/g, "-");
  };

  const handleMouseEnter = () => {
    document.body.classList.add("body-hover");
  };

  const handleMouseLeave = (event) => {
    document.body.classList.remove("body-hover");

    // Reset image transform on mouse leave
    const link = event.currentTarget;
    const image = link.querySelector(".overlay-image img");
    if (image) {
      image.style.transform = "translate(0, 0)";
    }
  };

  const handleMouseMove = (event) => {
    const link = event.currentTarget;
    const image = link.querySelector(".overlay-image img");
    const linkRect = link.getBoundingClientRect(); // Get link dimensions and position

    // Get the width and height of the image
    const imageWidth = image.offsetWidth;
    const imageHeight = image.offsetHeight;

    // Calculate mouse position relative to the link
    const mouseX = event.clientX - linkRect.left; // Mouse X position relative to the link
    const mouseY = event.clientY - linkRect.top; // Mouse Y position relative to the link

    // Calculate the translate values to center the cursor over the image
    const translateX = mouseX - imageWidth / 2; // Center the image over the cursor
    const translateY = mouseY - imageHeight / 10; // Center the image vertically over the cursor

    // Apply the transform
    image.style.transform = `translate(${translateX}px, ${translateY}px)`; // Move image with mouse
  };

  return (
    <div className="homepage-container" style={{ height: containerHeight }}>
      {projects != null && projects.length > 0 ? (
        <div className="projectnames-container">
          {projects.map((project, index) => (
            <Link
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={(e) => handleMouseMove(e, index)} // Track mouse movement
              to={`/project/${toSlug(project.name)}`}
              key={project.id}
              className="project-link"
            >
              {project.name}
              {lastInRow.includes(index) ? null : (
                <span className="separator">&nbsp;/&nbsp;</span>
              )}
              <div
                className={`overlay-image ${imageClasses[index] || ""}`}
                onLoad={(e) => handleImageLoad(index, e.target)}
              >
                <img
                  id={`overlay-image-${index}`}
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES + project.imageUrl
                  }
                  alt={project.name}
                  className="hover-image"
                />
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Home;
