import React, { useEffect, useState, useRef } from "react";
import "./AboutUsDashboard.css";
import DashSideBar from "../../shared/DashSideBar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button } from "primereact/button";
import axios from "axios";
import { Toast } from "primereact/toast";

function AboutUsDashboard() {
  const [content, setContent] = useState(""); // Desktop content
  const [mobileContent, setMobileContent] = useState(""); // Mobile content
  const toast = useRef(null);
  const [aboutcontent, setAboutcontent] = useState(null); // Desktop content object
  const [aboutMobileContent, setAboutMobileContent] = useState(null); // Mobile content object

  // Handle desktop content change
  const handleModelChange = (model) => {
    setContent(model);
  };

  // Handle mobile content change
  const handleMobileModelChange = (model) => {
    setMobileContent(model);
  };

  useEffect(() => {
    getAboutUsPageContent(); // Load desktop content
    getAboutUsSecondPageContent(); // Load mobile content
  }, []);

  // Fetch the desktop content (first entry)
  const getAboutUsPageContent = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "api/AboutPages/getFirstOne"
      );
      if (response.data) {
        setAboutcontent(response.data);
        setContent(response.data.description); // Set desktop content
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error Fetching Desktop Content",
        life: 3000,
      });
    }
  };

  // Fetch the mobile content (second entry)
  const getAboutUsSecondPageContent = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "api/AboutPages/getSecondOne"
      );
      if (response.data) {
        setAboutMobileContent(response.data);
        setMobileContent(response.data.description); // Set mobile content
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error Fetching Mobile Content",
        life: 3000,
      });
    }
  };

  // Save desktop content
  const SaveText = async () => {
    let data = { Id: aboutcontent.id, Description: content };
    try {
      const response = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "api/AboutPages/" + aboutcontent.id,
        data
      );

      if (response.data.success) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Desktop Content Saved",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Content Not Saved",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error Saving Desktop Content",
        life: 3000,
      });
    }
  };

  // Save mobile content
  const SaveMobileText = async () => {
    let data = { Id: aboutMobileContent.id, Description: mobileContent };
    try {
      const response = await axios.put(
        process.env.REACT_APP_BACKEND_URL +
          "api/AboutPages/" +
          aboutMobileContent.id,
        data
      );

      if (response.data.success) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Mobile Content Saved",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Mobile Content Not Saved",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error Saving Mobile Content",
        life: 3000,
      });
    }
  };

  return (
    <div className="col-12 d-flex">
      <div className="col-3">
        <DashSideBar />
      </div>
      <div className="col-9">
        <div className="aboutuspageTitle">
          <Toast ref={toast} />
          {/* Desktop Content */}
          <div className="titleandsave d-flex justify-content-between">
            <h3>About Us Page Desktop Content</h3>
            <Button
              label="Save"
              icon="pi pi-plus"
              severity="success"
              onClick={SaveText}
            />
          </div>
          <ReactQuill value={content} onChange={handleModelChange} />

          {/* Mobile Content */}
          <div className="mobilecontent">
            <div className="titleandsave d-flex justify-content-between">
              <h3>About Us Page Mobile Content</h3>
              <Button
                label="Save"
                icon="pi pi-plus"
                severity="success"
                onClick={SaveMobileText}
              />
            </div>
            <ReactQuill
              value={mobileContent}
              onChange={handleMobileModelChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsDashboard;
