import React from "react";
import "./Loading.css";
function Loading() {
  return (
    <div className="loading-container">
      <div className="logoLoading">
        <h1>TAREK SABBOUH</h1>
        <div className="spinnerLoading">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      </div>
    </div>
  );
}

export default Loading;
