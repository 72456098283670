import React, { useEffect, useState, useRef } from "react";
import "./VideosSlider.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { Navigation } from "swiper/modules";

export default function VideosSlider({ videos }) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [videoStyle, setVideoStyle] = useState({});
  const mediaQuery = window.matchMedia("(max-width: 600px)");
  const [isSwiping, setIsSwiping] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0); // Track last scroll position
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    setIsSmallScreen(mediaQuery.matches);

    const updateVideoSize = () => {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Calculate the dynamic aspect ratio based on the viewport size
      const dynamicAspectRatio = viewportWidth / viewportHeight;

      // Adjust the video style dynamically based on the aspect ratio
      setVideoStyle({
        width: `${viewportWidth}px`,
        height: `${viewportHeight}px`,
        aspectRatio: dynamicAspectRatio, // Set aspect ratio based on viewport dimensions
      });
    };

    // Call it initially to set the right size
    updateVideoSize();

    // Add event listener to update video size on window resize
    window.addEventListener("resize", updateVideoSize);

    return () => {
      // Clean up the event listener
      window.removeEventListener("resize", updateVideoSize);
    };
  }, [mediaQuery.matches]);
  useEffect(() => {
    const swiperInstance = document.querySelector(
      ".swipervideo-container"
    )?.swiper;

    if (!swiperInstance) return;

    const handleVideoControls = (disable) => {
      const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
      const videoElement = activeSlide.querySelector("video");
      if (videoElement) {
        videoElement.controls = !disable;
      }
    };

    const pauseAndResetPreviousVideo = () => {
      swiperInstance.slides.forEach((slide, index) => {
        const videoElement = slide.querySelector("video");
        if (videoElement && index !== swiperInstance.activeIndex) {
          videoElement.pause();
          videoElement.currentTime = 0.002; // Reset previous video to the beginning
        }
      });
    };

    swiperInstance.on("touchStart", () => {
      setIsSwiping(true); // Set swiping state
    });

    swiperInstance.on("touchEnd", () => {
      setIsSwiping(false); // Reset swiping state
      handleVideoControls(false); // Enable controls after swiping ends
      pauseAndResetPreviousVideo(); // Reset previous video on slide change
    });

    swiperInstance.on("slideChange", pauseAndResetPreviousVideo); // Handle slide change to reset previous videos

    return () => {
      swiperInstance.off("touchStart");
      swiperInstance.off("touchEnd");
      swiperInstance.off("slideChange");
    };
  }, [isSmallScreen, isSwiping]);
  useEffect(() => {
    //when the user scroll down the video should pause
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Check if the user scrolled down
      if (currentScrollY > lastScrollY) {
        const videos = document.querySelectorAll("video");
        videos.forEach((video) => {
          video.pause(); // Pause all videos on scroll down
        });
      }

      // Update last scroll position
      setLastScrollY(currentScrollY);
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the scroll event listener
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]); // Depend on lastScrollY to update on change

  const handlePlay = (event) => {
    const videoElement = event.target;
    videoElement.muted = false; // Unmute the video when it starts playing
  };
  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.update();
    }
  }, [swiperRef, prevRef, nextRef]);

  const handleScrollDown = () => {
    const infosSection = document.querySelector(".infos-container");
    const imagesSection = document.querySelector(".imagesSlider-container");

    if (infosSection && imagesSection) {
      const infosPosition =
        infosSection.getBoundingClientRect().top + window.scrollY;
      const imagesPosition =
        imagesSection.getBoundingClientRect().top + window.scrollY;

      // Calculate the midpoint between the two sections
      const scrollToPosition = (infosPosition + imagesPosition) / 2 - 200;

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="slider-container">
      {videos != null && videos.length > 1 && (
        <>
          <div ref={prevRef} className="custom-prev-arrow">
            <img src="/images/leftarrow.svg" alt="left arrow" />
          </div>
          <div ref={nextRef} className="custom-next-arrow">
            <img src="/images/rightarrow.svg" alt="right arrow" />
          </div>
        </>
      )}

      <div onClick={handleScrollDown} className="custom-down-arrow-video">
        <img src="/images/downarrow.svg" alt="down arrow" />
      </div>
      <Swiper
        modules={[Navigation]}
        slidesPerView={1}
        loop={true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="swipervideo-container"
      >
        {videos != null &&
          videos
            .sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))
            .map((video, index) => {
              return (
                <SwiperSlide key={index}>
                  <video
                    muted={isSmallScreen}
                    src={`${
                      process.env.REACT_APP_BACKEND_URL_VIDEOS
                    }${encodeURIComponent(video.videoUrl)}#t=0.001`}
                    style={videoStyle}
                    playsInline
                    controls="controls"
                    preload="auto"
                    autoPlay={false}
                    loop
                    onPlay={handlePlay}
                  >
                    <source
                      src={`${
                        process.env.REACT_APP_BACKEND_URL_VIDEOS
                      }${encodeURIComponent(video.videoUrl)}#t=0.001`}
                      type="video/mp4"
                    />
                  </video>
                </SwiperSlide>
              );
            })}
      </Swiper>
    </div>
  );
}
