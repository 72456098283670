import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

// Create a context for projects
export const ProjectsContext = createContext();

// Create a provider component
export const ProjectsProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch projects one time when the provider is mounted
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "api/Projects"
        );
        setLoading(false);
        const sortedProjects = response.data.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );
        setProjects(sortedProjects); // Assuming the response contains the projects array
      } catch (error) {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  return (
    <ProjectsContext.Provider value={{ projects, loading }}>
      {children}
    </ProjectsContext.Provider>
  );
};
